<template>
    <intersect :threshold="[0.4]" @enter="animateNumbers">
        <section class="kpis bg-black px-2 pt-8 pb-12 lg:py-8 text-center">
            <div class="grid-container">
                <div class="lg:mx-auto lg:w-5/6">
                    <h2 class="text-white">
                        {{ heading }}
                    </h2>
                    <p class="mt-1 text-lg lg:text-2xl text-greylighter leading-relaxed lg:leading-normal">
                        {{ description }}
                    </p>
                </div>

                <div class="flex flex-col lg:flex-row lg:justify-between items-center lg:mt-5 lg:w-2/3 lg:mx-auto">
                    <div v-for="(kpi, key) in animatedKpis" :key="key" class="mt-4 lg:mt-0 lg:w-1/6">
                        <div class="font-gentona-extrabold text-white text-4xl lg:text-10xl lg:leading-none">
                            <span>{{ kpi.value }}</span><span v-if="kpis[key].valueUnit">{{ kpis[key].valueUnit }}</span>
                        </div>
                        <p class="font-gentona-bold text-lg lg:text-2xl text-white">
                            {{ kpis[key].baseline }}
                        </p>
                    </div>
                </div>
                <div class="flex flex-col lg:flex-row items-center justify-center mt-6">
                    <template v-for="(cta, index, key) in ctas">
                        <a v-if="cta.callToAction.value && cta.callToAction.customText" :key="key" :target="cta.callToAction.target" :href="cta.callToAction.value" :class="{'btn--ghost-white' : index == 0, 'btn--blue mt-3 lg:mt-0 lg:ml-4' : index > 0}" class="btn btn--m">{{ cta.callToAction.customText }}</a>
                    </template>
                </div>
            </div>
        </section>
    </intersect>
</template>

<script>
    import gsap from "gsap";
    import Intersect from 'vue-intersect';

    export default {
        name: "Kpis",

        props: {
            heading: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            kpis: {
                type: Array,
                default: null,
            },
            ctas: {
                type: Array,
                default: null,
            },
        },

        components: {
            Intersect,
        },

        data() {
            return {
                animatedKpis: [],
                isAnimationDone: false,
            }
        },

        mounted() {
            for (const i in this.kpis) {
                this.animatedKpis.push({
                    value: 0,
                });
            }

            console.log(this.ctas);
        },

        methods: {
            animateNumbers() {
                if(this.isAnimationDone) {
                    return;
                }

                this.isAnimationDone = true;

                for (const i in this.animatedKpis) {
                    gsap.to(this.animatedKpis[i], {
                        value: `+=${this.kpis[i].kpiValue}`,
                        roundProps: "value",
                        duration: 2,
                    });
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .kpis {

    }
</style>
