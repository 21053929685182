<template>
    <intersect :threshold="[0.8]" @enter="play">
        <section class="cycle pt-8 pb-12 lg:py-8">
            <div class="w-full lg:w-5/6 mx-auto mb-1 lg:mb-2 px-2 lg:px-0">
                <div class="cycle__heading">
                    {{ heading }}
                    <transition name="keyword-fade" mode="out-in">
                        <strong :key="currentKeywordId">
                            {{ currentKeyword }}
                        </strong>
                    </transition>
                </div>
            </div>

            <div class="relative flex flex-col lg:flex-row lg:flex-wrap lg:justify-center">
                <span class="lg:hidden absolute top-0 right-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="168" viewBox="0 0 50 168">
                        <path fill="none" fill-rule="evenodd" :stroke="curvesColor" stroke-width="4" d="M21.193 2.486C67.516 33.079 90.406 123.446 1.496 165.38"/>
                    </svg>
                </span>

                <div class="relative hidden lg:flex justify-end items-end w-1/4 my-2 pt-2 pr-2">
                    <div ref="cover4" class="absolute top-0 right-0 w-full h-full bg-white"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="191" height="134" viewBox="0 0 191 134">
                        <path fill="none" fill-rule="evenodd" :stroke="curvesColor" stroke-width="4" d="M189.778 2.517C89.516 20.285 17.348 66.355 1.933 133.124"/>
                    </svg>
                </div>

                <div class="order-1 lg:order-none w-full lg:w-1/4 my-2">
                    <step :step="steps[0]"></step>
                </div>

                <div class="relative hidden lg:flex justify-start items-end w-1/3 my-2 pl-2">
                    <div ref="cover1" class="absolute top-0 right-0 w-full h-full bg-white"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="78" viewBox="0 0 129 78">
                        <path fill="none" fill-rule="evenodd" :stroke="curvesColor" stroke-width="4" d="M127.65 75.647C92.54 48.068 49.562 22.925.755 1.983"/>
                    </svg>

                </div>

                <div class="order-4 lg:order-none w-full lg:w-1/4 my-2">
                    <step :step="steps[3]"></step>
                </div>

                <div class="hidden lg:flex w-1/3 my-2"></div>

                <div class="order-2 lg:order-none w-full lg:w-1/4 my-2">
                    <step :step="steps[1]"></step>
                </div>

                <div class="relative hidden lg:flex justify-end items-start w-1/4 my-2 pr-2">
                    <div ref="cover3" class="absolute top-0 left-0 w-full h-full bg-white"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="133" height="99" viewBox="0 0 133 99">
                        <path fill="none" fill-rule="evenodd" :stroke="curvesColor" stroke-width="4" d="M2.396 1.622c31.8 34.922 75.995 67.65 129.213 95.269"/>
                    </svg>

                </div>

                <div class="order-3 lg:order-none w-full lg:w-1/4 my-2">
                    <step :step="steps[2]"></step>
                </div>

                <div class="relative hidden lg:flex justify-start items-start w-1/3 my-2 pl-2">
                    <div ref="cover2" class="absolute top-0 left-0 w-full h-full bg-white"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="213" height="118" viewBox="0 0 213 118">
                        <path fill="none" fill-rule="evenodd" :stroke="curvesColor" stroke-width="4" d="M1.138 115.984C105.134 103.528 184.163 62.692 210.464.842"/>
                    </svg>
                </div>
            </div>
        </section>
    </intersect>
</template>

<script>
    import Step from "./Step";
    import Intersect from "vue-intersect"
    import gsap from "gsap";
    import {CSSPlugin} from "gsap/CSSPlugin";
    import {Linear} from "gsap/all";

    export default {
        name: "Cycle",

        components: {
            Intersect,
            Step,
        },

        props: {
            heading: {
                type: String,
                default: ''
            },
            keywordsList: {
                type: Array,
                default: null
            },
            steps: {
                type: Array,
                required: true
            },
            curvesColor: {
                type: String,
                required: true
            },
        },

        data() {
            return {
                isAnimationStarted: false,
                timeline: null,
                currentKeywordId: 0,
                intervalId: null,
            }
        },

        computed: {
            currentKeyword() {
                return this.keywordsList[this.currentKeywordId].keyword;
            }
        },

        mounted() {
            gsap.registerPlugin(CSSPlugin);
            this.prepareTimeline();
        },

        destroyed() {
            clearInterval(this.intervalId);
        },

        methods: {
            prepareTimeline() {
                this.timeline = gsap.timeline({paused: true});

                this.timeline.to(this.$refs.cover1, {
                    width: 0,
                    duration: 0.3,
                    ease: Linear.easeNone,
                });

                this.timeline.to(this.$refs.cover2, {
                    width: 0,
                    duration: 0.3,
                    ease: Linear.easeNone,
                });

                this.timeline.to(this.$refs.cover3, {
                    width: 0,
                    duration: 0.3,
                    ease: Linear.easeNone,
                });

                this.timeline.to(this.$refs.cover4, {
                    width: 0,
                    duration: 0.3,
                    ease: Linear.easeNone,
                });
            },

            play() {
                if (this.isAnimationStarted) {
                    return;
                }

                this.isAnimationStarted = true;

                this.timeline.play();

                this.intervalId = setInterval(this.incrementCurrentKeywordId, 2500);
            },

            incrementCurrentKeywordId() {
                if(this.currentKeywordId === this.keywordsList.length - 1) {
                    this.currentKeywordId = 0;
                    return;
                }

                this.currentKeywordId++;
            }
        }
    }
</script>

<style lang="scss">
    .cycle {
        &__heading {
            @apply font-questa-regular text-greylighter;
            max-width: 60rem;
            font-size: 1.5rem;
            line-height: (32/24);

            strong {
                @apply font-gentona-bold text-black;
            }

            @screen lg {
                font-size: 2.5rem;
                line-height: normal;
            }
        }
    }

    .keyword-fade-enter-active, .keyword-fade-leave-active {
        transition: opacity 0.3s ease-in-out;
    }
    .keyword-fade-enter, .keyword-fade-leave-to {
        opacity: 0;
    }
</style>
