import "./assets/styles/main.scss";

import 'intersection-observer';

import Vue from "vue";
import CsrfInput from "./app/components/CsrfInput";
import Hero from "./app/components/Hero"
import Kpis from "./app/components/Kpis";
import Logos from "./app/components/Logos"
import Cycle from "./app/components/Cycle";
import Notice from "./app/components/Notice";
import CaseStudies from "./app/components/CaseStudies";
import ContactFormStickyBar from "./app/components/ContactFormStickyBar";
import CarouselCasestudies from "./app/components/CarouselCasestudies";
import CarouselMethodo from "./app/components/CarouselMethodo";
import StickyCta from "./app/components/StickyCta";

new Vue({
    el: "#app",

    components: {
        CsrfInput,
        Hero,
        Kpis,
        Logos,
        Cycle,
        Notice,
        CaseStudies,
        ContactFormStickyBar,
        CarouselCasestudies,
        CarouselMethodo,
        StickyCta
    }
});
