<template>
    <transition name="fade" mode="out-in">
        <div v-if="isNoticeVisible" class="notice fixed z-10 top-0 left-0 right-0 w-full flex justify-center">
            <div :class="background" class="md:mt-2 px-3 py-1 font-gentona-bold text-lg text-white">
                {{ content }}
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Notice",

        props: {
            content: {
                type: String,
                required: true,
            },
            isError: {
                tyupe: Boolean,
                default: false,
            }
        },

        data() {
            return {
                isNoticeVisible: false
            }
        },

        computed: {
            background() {
                return this.isError ? 'bg-error' : 'bg-blue';
            }
        },

        mounted() {
            this.isNoticeVisible = true;

            setTimeout(() => {
                this.isNoticeVisible = false;
            }, 5000);
        }
    }
</script>

<style scoped lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s, transform .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
        transform: translateY(-100px);
    }
</style>
