<template>
    <section class="carousel-casestudies py-5 lg:pt-9 lg:pb-7 bg-whitedarkest overflow-hidden">
        <div class="grid-container">
            <carousel :pagination-size="6" pagination-color="#a6a6a6" :per-page="1" :navigation-enabled="true" :loop="true" class="lg:mt-5 -mx-4">
                <slide v-for="(card, index, key) in casestudies" :key="key">
                    <div class="px-4">
                        <div class="carousel-casestudies__card bg-white px-2 py-4 lg:pt-6 lg:pb-7 lg:px-6 flex flex-col lg:flex-row items-center">
                            <div class="flex-shrink-0 overflow-hidden rounded-full">
                                <img :src="card.image" class="carousel-casestudies__card-image"/>
                            </div>
                            <div class="mt-4 lg:mt-0 lg:ml-10">
                                <p v-if="card.text" class="body1 mt-2">{{ card.text }}</p>
                                <p v-if="card.author" class="body2 mt-1 text-specialgrey">{{ card.author }}</p>
                                <a v-if="card.callToAction.value" :href="card.callToAction.value" :target="card.callToAction.target" class="link link--m link--blue mt-2">{{ card.callToAction.customText }}</a>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </section>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: "CarouselCasestudies",

        components: {
            Carousel,
            Slide
        },

        props: {
            casestudies: {
                type: Array,
                default: null,
            }
        },
        mounted(){
            console.log(this.casestudies);
        }
    }
</script>

<style lang="scss">
    .carousel-casestudies {
        &__card {
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
        }

        &__card-image {
            width : 7.75rem;
            height : 7.75rem;

            @screen lg {
                width : 16.375rem;
                height : 16.375rem;
            }
        }

        .VueCarousel-navigation-button {
            @apply opacity-100 cursor-pointer;
            width : 2.5rem;
            height : 2.5rem;
            background-size : 2.5rem 2.5rem;
            background-image : url('../../static/images/icons/arrow-blue.svg');
            font-size : 0;

            top : inherit !important;
            bottom : -20px !important;

            @screen lg {
                width : 4rem;
                height : 4rem;
                background-size : 100%;
                bottom : inherit !important;
                top : 50% !important;
            }
        }

        .VueCarousel-dot-container {
            margin-top : 0px !important;
        }

        .VueCarousel-navigation-next {
            right : 32px !important;
            transform : translateY(-50%) !important;
            @screen lg {
                transform : translateY(-50%) translateX(50%) !important;
            }
        }

        .VueCarousel-navigation-prev {
            left : 32px !important;
            transform : rotate(-180deg) translateY(50%) !important;

            @screen lg {
                transform : rotate(-180deg) translateY(50%) translateX(50%) !important;
            }
        }

        .VueCarousel-wrapper {
            @apply py-4;
        }
    }
</style>
