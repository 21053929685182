<template>
    <section :id="`case-studies-${id}`" class="case-studies flex flex-col items-center justify-center mt-4 lg:mt-10 lg:my-3 lg:mb-12">
        <div class="grid-container">
            <div v-if="heading" class="w-full mb-4 lg:mb-5">
                <h2>{{ heading }}</h2>
            </div>
            <div class="flex flex-wrap w-full justify-between -mx-2">
                <template v-for="(caseStudy, key) in caseStudies">
                    <article
                        :key="`overview-${key}`"
                        :class="overviewClass(key)"
                        class="case-studies__overview relative flex flex-col items-start w-full lg:w-1/3 px-2 pb-3 lg:pb-4"
                    >
                        <a :href="`#case-study-${id}-${key}`" class="gtm-case-study" @click="currentCaseStudyId = key">
                            <img :src="caseStudy.overview.image" :alt="caseStudy.overview.heading" class="mb-2">
                        </a>
                        <p class="body2 text-specialgrey">{{ caseStudy.overview.overline }}</p>
                        <h3>{{ caseStudy.overview.heading }}</h3>
                        <p class="body2 text-black mt-1">{{ caseStudy.overview.description }}</p>

                        <a :href="`#case-study-${id}-${key}`" class="gtm-case-study link link--s link--blue mt-1" @click="currentCaseStudyId = key">
                            {{ openButtonTitle }}
                        </a>
                    </article>

                    <modal :key="`case-study-${key}`" :is-opened="currentCaseStudyId === key" @close="currentCaseStudyId = null">
                        <div class="flex flex-col">
                            <h3 class="pt-3 pb-4 px-4 lg:px-8">{{ caseStudy.overview.heading }}</h3>
                            <div v-if="caseStudy.media.type === 'image'" class="case-studies__image w-full flex items-start mt-4 lg:mt-0">
                                <img
                                    :src="caseStudy.media.url"
                                    :alt="caseStudy.media.title"
                                >
                            </div>
                            <div v-if="caseStudy.media.type === 'video'" class="w-full flex items-start mt-4 lg:mt-0">
                                <div class="w-full lg:bg-white">
                                    <video
                                        :src="caseStudy.media.url"
                                        class="gtm-video w-full"
                                        controls
                                    ></video>
                                </div>
                            </div>
                            <div class="w-full pt-5 px-4 lg:px-8">
                                <h3>{{ caseStudy.heading }}</h3>
                                <div v-html="caseStudy.textContent" class="case-studies__text-content body2 mt-3"></div>
                                <div v-if="caseStudy.kpis.kpis.length > 0" class="case-studies__kpi-content mt-5">
                                    <div class="flex flex-col lg:flex-row items-center lg:items-start lg:justify-center pb-2 lg:pb-0">
                                        <div v-for="(kpi, key) in caseStudy.kpis.kpis" :key="key" class="lg:w-1/3 xl:w-1/4 my-2 lg:mt-5 lg:mx-4 text-center">
                                            <p class="h1">{{ kpi.value }}</p>
                                            <p class="h4">{{ kpi.subtitle }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
    import Modal from './Modal';

    export default {
        name: "CaseStudies",

        components : { Modal },

        props: {
            heading: {
                type: String,
                default: null
            },
            openButtonTitle: {
                type: String,
                default: '',
            },
            closeButtonTitle: {
                type: String,
                default: '',
            },
            caseStudies: {
                type: Array,
                default: null
            }
        },

        data() {
            return {
                currentCaseStudyId: null,
                id: null,
            }
        },

        mounted() {
            this.id = this._uid
        },

        methods: {
            overviewClass(key) {
                return this.currentCaseStudyId === key ? `case-studies__overview--opened lg:order-${key + 1}`: `lg:order-${key + 1}`;
            }
        }
    }
</script>

<style lang="scss">
    .case-studies {
        &__overview {
            @screen lg {
                &::after {
                    @apply absolute w-full bg-transparent;
                    content: '';
                    top: 100%;
                    height: 0.4375rem;
                    transition: background-color 0.2s ease-in-out;
                }
            }

            &--opened {
                @screen lg {
                    &::after {
                        @apply bg-black;
                    }
                }
            }
        }

        &__image {
            max-height : 13.125rem;

            @screen lg {
                max-height: 31rem;
            }

            > img {
                max-height : 100%;
            }
        }

        &__text-content {
            h4 {
                @apply mt-5 mb-2;
            }

            ul {
                @apply list-disc leading-normal pl-2;
            }
        }

        &__kpi-content {
            &:before {
                content : '';
                @apply bg-blue block mx-auto;
                width : 10.75rem;
                height : 0.25rem;

                @screen lg {
                    width : 27.5rem;
                }
            }
        }
    }
</style>
