<template>
    <article class="step px-2 lg:px-0">
        <p class="text-greylighter lg:text-xl">{{ step.overline }}</p>
        <p class="font-gentona-bold text-black text-lg lg:text-2xl">{{ step.heading }}</p>
        <div class="text-greylighter text-sm lg:text-base" v-html="step.textContent"></div>
    </article>
</template>

<script>
    export default {
        name: "Step",

        props: {
            step: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
