<template>
    <intersect :threshold="[0.4]" @enter="isAnimated = true">
        <section
            :class="{
                'logos--animated': isAnimated,
                'bg-black' : configuration.backgroundColor == 'dark'
            }"
             class="logos relative bg-white px-2 pt-6 pb-5 lg:py-4 lg:px-0"
        >
            <div :class="{'text-center' : alignment == 'center'}" class="grid-container">
                <h2 :class="{'text-white' : configuration.textColor == 'light'}">{{ heading }}</h2>

                <div
                    :class="{
                        'justify-start' : alignment == 'left',
                        'justify-center' : alignment == 'center'
                    }"
                    class="container mx-auto flex flex-wrap">
                    <div v-for="(logo, key) in logos" :key="key" class="w-1/2 md:w-1/3 lg:w-1/6 flex justify-center items-center my-2 px-2">
                        <img :src="logo.url" :srcset="logo.url2x" :alt="logo.title">
                    </div>
                </div>

                <slot></slot>
            </div>
        </section>
    </intersect>
</template>

<script>
    import Intersect from 'vue-intersect';

    export default {
        name: "Logos",

        components: {
            Intersect
        },

        props: {
            heading: {
                type: String,
                default: ''
            },
            logos: {
                type: Array,
                default: null
            },
            smallTitle: {
                type: String,
                default: ''
            },
            alignment: {
                type: String,
                default: ''
            },
            configuration: {
                type: Object,
                default: () => {}
            },
        },

        data() {
            return  {
                isAnimated: false
            }
        },

        mounted(){
            console.log(this.configuration);
        }
    }
</script>

<style scoped lang="scss">
    .logos {
        &--animated {
            &::before {
                height: 5rem;
            }

            @screen lg {
                &::before {
                    height: 8.75rem;
                }
            }
        }
    }
</style>
