<template>
    <section class="carousel-methodo py-5 lg:pt-9 lg:pb-7 bg-whitedarkest overflow-hidden">
        <div class="grid-container">
            <h2>{{ heading }}</h2>
            <div class="body1">{{ text }}</div>
            <carousel :pagination-size="6" pagination-color="#a6a6a6" :per-page-custom="[[320, 1], [992, 2.4]]" :navigation-enabled="true" :loop="true" class="mt-3 lg:mt-5 -mx-4">
                <slide v-for="(card, index, key) in cards" :key="key">
                    <div class="px-4">
                        <div class="carousel-methodo__card py-2 px-4 bg-white border-gold border-b-4">
                            <p class="number text-gold">{{ index + 1}}/</p>
                            <h3 class="mt-2">{{ card.heading }}</h3>
                            <p class="body2 mt-2">{{ card.text }}</p>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </section>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: "CarouselMethodo",

        components: {
            Carousel,
            Slide
        },

        props: {
            heading: {
                type: String,
                default: '',
            },
            text: {
                type: String,
                default: '',
            },
            cards: {
                type: Array,
                default: null,
            }
        }
    }
</script>

<style lang="scss">
    .carousel-methodo {
        &__card {
            min-height : 30rem;
        }

        .VueCarousel-navigation-button {
            @apply opacity-100 cursor-pointer;
            width : 2.5rem;
            height : 2.5rem;
            background-size : 2.5rem 2.5rem;
            background-image : url('../../static/images/icons/arrow-blue.svg');
            font-size : 0;

            top : inherit !important;
            bottom : -20px !important;

            @screen lg {
                width : 4rem;
                height : 4rem;
                background-size : 100%;
                bottom : inherit !important;
                top : 50% !important;
            }
        }

        .VueCarousel-dot-container {
            margin-top : 0px !important;
        }

        .VueCarousel-navigation-next {
            right : 32px !important;
            transform : translateY(-50%) !important;
            @screen lg {
                transform : translateY(-50%) translateX(50%) !important;
            }
        }

        .VueCarousel-navigation-prev {
            left : 32px !important;
            transform : rotate(-180deg) translateY(50%) !important;

            @screen lg {
                transform : rotate(-180deg) translateY(50%) translateX(50%) !important;
            }
        }

        .VueCarousel-wrapper {
            @apply py-4;
            overflow : visible;
        }
    }
</style>
