<template>
    <section ref="footerSticky" class="sticky-cta bg-white fixed bottom-0 left-0 right-0">
        <div class="grid-container">
            <div class="grid-row py-2">
                <div class="grid-col w-full flex flex-col md:flex-row justify-center items-center">
                    <a v-if="cta" :href="cta.value" :target="cta.target" class="btn btn--m btn--blue">{{ cta.customText }}</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import gsap from 'gsap';

    export default {
        name : 'footer-sticky',

        props: {
            cta : { type : Object, default : () => {}},
        },

        computed : {
            direction(){
                return document.querySelector('#footer');
            }
        },

        mounted(){
            window.addEventListener('scroll', this.scrollHandler);

            this.scrollHandler();
        },

        methods : {
            scrollHandler(){
                let scrolltop = window.pageYOffset;

                gsap.to(this.$refs.footerSticky, {
                    y : (scrolltop > 100 && scrolltop + window.innerHeight <= this.direction.offsetTop) ? '0%' : '100%',
                    duration : 0.7,
                    ease : "power3.out"
                });
            }
        }
    }
</script>
