<template>
    <section class="hero flex items-stretch relative" :style="`background-color: ${backgroundColor}`">
        <div class="hero__background absolute inset-0">
            <img v-if="backgroundImageDesktop" :src="backgroundImageDesktop" class="hidden lg:block absolute object-cover w-full h-full"/>
            <img v-if="backgroundImageMobile" :src="backgroundImageMobile" class="lg:hidden object-cover w-full h-full"/>
            <div class="absolute bg-black inset-0 w-full h-full" :style="'opacity : ' + overlayOpacity/100"></div>
        </div>
        <div class="grid-container relative">
            <div class="flex-1 flex flex-col items-center w-full h-full">
                <div class="w-full md:w-5/6 flex flex-col justify-center items-center h-full">
                    <h1 class="h1 text-white text-center">{{ heading }}</h1>
                    <h3 class="text-white text-center mt-1">{{ subtitle }}</h3>
                    <a v-if="cta.value && cta.customText" :href="cta.value" :target="cta.target" class="btn btn--m btn--blue mt-4 lg:mt-6">{{ cta.customText}}</a>
                </div>

                <ul class="hero__keywords flex flex-row items-center justify-center flex-wrap w-full absolute bottom-0 px-2 pb-2 lg:pb-5 ">
                    <li ref="listItem" v-for="(item, index, key) in keywords" :key="key" class="lg:mx-0 flex items-center flex-wrap lg:my-1 body2 text-white leading-tight">
                        <span v-if="index > 0" class="w-2 h-px bg-white mx-1 inline-block"></span>
                        {{ item.keyword }}
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import gsap from "gsap";
    import {CSSPlugin} from "gsap/CSSPlugin";
    import {ScrollToPlugin} from "gsap/ScrollToPlugin";
    import {Power1, Power4} from "gsap/all";

    export default {
        name: "Hero",

        props: {
            heading: {
                type: String,
                default: '',
            },
            subtitle: {
                type: String,
                default: '',
            },
            keywords: {
                type: Array,
                default: null,
            },
            backgroundColor: {
                type: String,
                default: '',
            },
            cta: {
                type: Object,
                default: () => {},
            },
            backgroundImageMobile: {
                type: String,
                default: '',
            },
            backgroundImageDesktop: {
                type: String,
                default: '',
            },
            overlayOpacity : {
                type : Number,
                default : 0
            }
        },

        mounted() {
            gsap.registerPlugin(CSSPlugin);
            gsap.registerPlugin(ScrollToPlugin);
        },

        methods: {
            getRandomFloatInclusive() {
                return Math.random() * (1 - 0.15) + 0.15;
            },

            animateOpacity() {
                let tl = gsap.timeline({
                    onComplete: this.animateOpacity
                });

                for (const i in this.keywords) {
                    tl.to(this.$refs.listItem[i], {
                        opacity: this.getRandomFloatInclusive(),
                        ease: Power1.easeInOut,
                        duration: 1
                    }, 0);
                }
            },

            scrollDown() {
                gsap.to(window, {
                    scrollTo: {
                        y: this.$el.nextElementSibling,
                        autoKill: false,
                    },
                    duration: 2,
                    ease: Power4.easeInOut,
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .hero {
        min-height : 41.25rem;
        @apply h-screen;

        @screen lg {
            min-height : 35rem;
            height: calc(100vh - 11.5rem);
        }

        &__keywords {
            height: 6.25rem;

            @screen md {
                height: auto;
            }
        }
    }
</style>
