<template>
    <intersect :threshold="[0.2]" @enter="isVisible = false" @leave="isVisible = true">
        <div id="contact-form-container">
            <slot></slot>

            <div :class="{'contact-form-sticky-bar--visible': isVisible}" class="contact-form-sticky-bar flex justify-center items-center fixed z-20 w-full px-2 bg-whitedarkest">
                <p class="mr-1 lg:mr-3 text-lg lg:text-2xl">
                    {{ stickyTitle }}
                </p>
                <a href="#contact-form-container" class="gtm-contact flex-shrink-0 btn btn--positive">
                    {{ stickyButtonTitle }}
                </a>
            </div>
        </div>
    </intersect>
</template>

<script>
    import Intersect from "vue-intersect";

    export default {
        name: "ContactFormStickyBar",

        components: {
            Intersect,
        },

        props: {
            stickyTitle: {
                type: String,
                default: '',
            },
            stickyButtonTitle: {
                type: String,
                default: '',
            }
        },

        data() {
            return {
                isVisible: true
            }
        },
    }
</script>

<style lang="scss">
    body {
        padding-bottom: 4.75rem;

        @screen lg {
            padding-bottom: 5.625rem;
        }
    }

    .contact-form-sticky-bar {
        height: 4.75rem;
        top: 100%;

        transition: transform 0.2s ease-in-out;

        @screen lg {
            height: 5.625rem;
        }

        &--visible {
            transform: translateY(-100%);
        }

        .btn {
            @apply text-lg;
            padding: 1.125rem 0.75rem;

            @screen lg {
                @apply text-2xl;
                padding: 1.25rem;
            }
        }
    }
</style>
